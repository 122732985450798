import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { GoMarkGithub } from "react-icons/go";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const NotFoundPage = () => (
  <Layout>
    <section class="section is-medium">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column has-text-centered">
            <h1 class="title">404 Page Not Found</h1>
            <p class="subtitle">
              The page you are looking for doesn't exist or an unexpected error
              has occurred
            </p>
            <div class="buttons are-large is-centered">
              <Link className="button is-primary is-light" to="/">
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
